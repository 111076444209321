#About {
	position: absolute;
	top: 0px;
	width: 100%;
	z-index: 20;
	font-size: 18px;
	//pointer-events: none;
	display: none;
	background-color: rgba(0, 0, 0, 0.9);
	height: 100%;
	color: #cccccc;
	overflow-y: auto;

	.textContent {
		pointer-events: all;
		padding-top: 100px;
		max-width: 666px;
		margin: auto;

		user-select: text;
		-webkit-user-select: text;
		text-align: center;
		margin-top: 50px;

		-moz-user-select: text;
		-khtml-user-select: text;
		-webkit-user-select: text;
		-ms-user-select: text;
		user-select: text;


		a {
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			color: #aaaaaa;
		}

		h2 {
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			font-family: 'ClaiveFont';
			font-size: 50px;
			color: #ffffff;
			margin-bottom: 20px;


		}

		h3 {
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
			color: #ffffff;
			margin-bottom: 10px;

		}

		p {
			color: #6c6c6c;
			font-weight: 500;
			//opacity: 0.5;
			font-size: 24px;
			//line-height: 22px;
			margin-bottom: 50px;
			-moz-user-select: text;
			-khtml-user-select: text;
			-webkit-user-select: text;
			-ms-user-select: text;
			user-select: text;
		}


	}


}

