.TestButton {
	margin-left: 10px;
	margin-top: 10px;
	display: inline-block;
	//width: 100px;
	padding: 10px;
	border-radius: 20px;
	background-color: white;
	color: black;
	cursor: pointer;

	.label{
		user-select: none;
		pointer-events: none;
		font-size: 14px;
	}
}
