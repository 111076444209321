#InstructionText {
	pointer-events: none;
	display: none;
	opacity: 0;
	position: absolute;
	z-index: 20;
	bottom: 35px;
	width: 100%;
	text-align: center;

	.textContent {
		font-size: 30px;
		font-weight: 500;
		opacity: 1;
		color: #ffffff;
	}
}

