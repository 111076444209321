#Preloader {
	position: absolute;
	display: grid;
	height: 100%;
	width: 100%;
	margin: 0;
	place-items: center center;
	background-color: #000000;
	z-index: 10;

	.centerContent {
		max-width: 500px;
		text-align: center;

		.preloadFont {
			font-family: ClaiveFont;
			visibility: hidden;
		}

		.headBangerLogo {
			opacity: 0;
			width: 293px;
			height: 62px;
			margin-bottom: 10px;
		}

		h1 {
			opacity: 0;
			font-size: 50px;
			font-weight: 500;
		}

		h2 {
			opacity: 0;
			color: #505050;
			font-size: 22px;
			font-weight: 500;
		}

		.highlight {
			color: #aaaaaa;
		}


		.note {
			font-size: 20px;
			opacity: 0;
			margin-top: -20px;

		}

		.begin {
			position: relative;
			margin-top: -40px;
			opacity: 0;
			color: #FFFFFF;
			margin-top: 30px;
			font-size: 26px;
			margin-bottom: 60px;
			cursor: pointer;
			pointer-events: none;
			font-weight: 500;

		}

		.divider {
			font-family: 'Claive-Ornamenets';
			font-size: 60px;
			line-height: 10px;
			margin-top: 0px;
			margin-bottom: 30px;
		}

		.loader {
			position: relative;
			top: 45px;
			opacity: 0;
			left: 0px;

			width: 500px;
			.loaderForeground {
				height: 1px;
				width: 0%;
				background-color: #ffffff;
			}
			.loaderBackground {
				height: 1px;
				width: 100%;
				background-color: #333333;
			}
		}

	}

	h3 {
		opacity: 0;
		color: #aaaaaa;

		text-align: center;
		position: absolute;
		font-size:22px;
		font-weight: 500;
		bottom: 40px;
		width: 100%;
		text-align: center;

		img {
			margin-left: 10px;
		}

	}
}
