#LevelScreen {
	pointer-events: none;
	display: none;
//	opacity: 0;
	position: absolute;
	z-index: 12;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);


	.centerImage {
		position: absolute;

		top: calc(50% - 1020px / 2);
		left: calc(50% - 1020px / 2);



		img {
			width: 1020px;
			height: 1020px;
			object-fit: scale-down;
		}
	}


	.centerContent {
		position: absolute;

		height: 400px;
		margin: 0;

		top: calc(100% / 2 - 300px / 2);

		font-size: 27px;
		text-align: center;

		.level {

			width: 100vw;
			font-family: ClaiveFont;
			font-size: 40px;
			margin-top: -80px;
			margin-bottom: 55px;
			color: #6c6c6c;
			text-align: center;

			.name {
				color: #ffffff;
			}

		//	opacity: 0.7;
		}

		.levelName {
			display: block;
			color: #ffffff;
			font-size: 70px;
			font-family: ClaiveFont;
			margin-bottom: 30px;
			font-weight: normal;
			font-style: normal;

		}

		img {
			vertical-align: middle;
			margin-right: 30px;

		}

		.text {
			vertical-align: middle;

		}

		.clickToContinue {
			cursor: pointer;
			color: #ffffff;

			margin-top: 60px;
			font-size: 40px;
		//	font-weight: bold;
		font-weight: 500;
			pointer-events: all;
		}


		.instructions {
		//	opacity: 0.7;
			width: 400px;
			margin-left: calc(100% / 2 - 400px / 2);
			margin-bottom: 26px;

		}

		.instructions_line_one {
		//	opacity: 0.7;
			margin-bottom: 15px;

		}

		.instructions_line_two {
			//	opacity: 0.7;
			margin-bottom: 15px;

			img {
			//	margin-left: 9px;
				margin-right: 38px;
			}
		}

		.instructions_line_three {
		//	opacity: 0.7;
		//	margin-bottom: 20px;

			img {
			//	margin-left: 9px;
				margin-right: 38px;
			}
		}
	}
}

