@font-face {
	font-family: 'ClaiveFont';
	src: url('/assets/fonts/Glaive-Regular1.woff') format('woff')
}
/*
@font-face {
	font-family: 'Claive-Ornamenets';
	src: url('/assets/fonts/Glaive-Ornaments.woff') format('woff')
}*/


html,
body {
	margin: 0;
	padding: 0;
	font-size: 22px;
	vertical-align: baseline;

	font-family: "Tungsten A", "Tungsten B";
	font-style: normal;
	font-weight: 400;

	//	text-rendering: auto;
	//	text-rendering: optimizeSpeed;
	//text-rendering: optimizeLegibility;
	//	text-rendering: geometricPrecision;
	//	text-rendering: auto;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;

	overflow: hidden;
}

body {
	overflow-y: hidden; //hidden;//scroll;
	overflow-x: hidden;
	background-color: $COLOR_BLACK;
	color: #6c6c6c;;
	font-weight: 400;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#Components {
	width: 100%;
	height: 100%;
	pointer-events: none;
}

h1 {
	font-weight: 500;
}

a,
a:visited,
a:active,
a:hover {
	color: inherit;
	text-decoration: none;
}

h1, h2, h3 {
	font-weight: 400;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	//border: 0;
	//font: inherit;
	//vertical-align: baseline;
}

#ViewContainer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	min-height: 100vh;
}

#TemplateLayer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	min-height: 100vh;
}


#_webcam {
	display:none;
}
