#Footer {
	position: absolute;
	bottom: 60px;
	width: 100%;
	color: #4f4e4e;
	z-index: 10;
	font-size: 22px;
	pointer-events: none;
	display: none;
	font-weight: 500;
	z-index: 30;

	a {
		color: #aaaaaa;
	}

	.leftSide {
		position: absolute;
		left: 40px;
		pointer-events: all;
}

	.rightSide {
		position: absolute;
		right: 40px;
		pointer-events: all;


	}

	.ornaments {
		font-family: 'Claive-Ornamenets';
		position: absolute;
		font-size: 80px;
		left: calc(50% - 50px);
		bottom: -38px;
		display: none;
	}

	.soundController {
		display: inline-block;
		cursor: pointer;
		padding-left: 10px;

		//display: none;
	}

	.soundOn {

	}

	.soundOff {
		position: relative;
		top: 0px;
		left: 0px;
		display: none;
	}


}

