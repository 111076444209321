#HeadBangerTop {
	opacity: 0;
	position: absolute;
	width: 100%;
	margin: 0;
	place-items: center center;
	z-index: 10;
	visibility: hidden;
	text-align: center;
	font-weight: 400;
	z-index: 30;


	.centerContent {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		.headBangerLogo {
			margin-top: 40px;
			opacity: 1;
			width: 146px;
			margin-bottom: -5px;
			opacity: 1;
		}

		h2 {
			opacity: 1;
			color: #cccccc;
			font-size: 14px;
			font-weight: 500;


		}
	}

	.statHeader {
		font-size: 22px;
		text-align: left;
		//opacity: 0.6;

	}

	.resultHeader {
		font-size: 22px;
		text-align: left;
		//opacity: 0.6;
		color: #aaaaaa;
	}

	.gameStatsLeft {
		position: absolute;
		top: 35px;
		left: 40px;
		display: flex;
		margin: 0 auto;
		font-weight: 500;

		.aboutButton {
			pointer-events: all;
			cursor: pointer;

			img {
				margin-right: 11px;

			}

			.text {
				display: inline-block;
				color: #aaaaaa;

			}
		}
	}

	.gameStatsRight {
		font-weight: 500;
		position: absolute;
		top: 35px;
		right: 40px;
		text-align: right;

		.statHeader {

			font-size: 22px;
			color: #4f4e4e;
			display: inline-block;
			margin-right: 5px;
			font-weight: 500;
		}


		.score {

		 }

		.highScore {
			display: none;
			margin-top: 20px;

		}

		.round {

			margin-bottom: 5px;
			display: inline-block;
		}

		.resultHeader {

			display: inline-block;
		}

		.lives {
			margin-right: 30px;
			width: 10%;
		}
	}
}
